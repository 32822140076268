import React from "react";
import { motion } from "framer-motion";
import {
  contentVariants,
  textContainerVariants,
} from "../animationVariants";

export const AnimatedH1 = ({ title, className }) => {
  return (
    <motion.h1 variants={textContainerVariants} className={className}>
      {title.split(" ").map((word, index) => (
        <motion.span key={index} variants={contentVariants}>
          {word}&nbsp;
        </motion.span>
      ))}
    </motion.h1>
  );
};
