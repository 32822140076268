import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export const BtnArrow = (props) => {
  return (
    <>
      <Link
        to={props.link}
        className={`an-button--arrow ${props.extraClasses} 
        ${props.islarge ? "an-button--large" : ""} 
        ${props.islight ? "an-button--light" : ""}`}
      >
        <span>{props.name}</span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 24">
          <g id="arrow" transform="translate(-292)">
            <rect
              id="Rectangle_848"
              data-name="Rectangle 848"
              width="28"
              height="24"
              transform="translate(292)"
              fill="currentColor"
              opacity="0"
            />
            <path
              id="bx-up-arrow-alt"
              d="M5.707,3.828V23.414h2V3.828L12,8.121l1.414-1.414L6.707,0,0,6.707,1.414,8.121Z"
              transform="translate(315.414 6) rotate(90)"
              fill="currentColor"
            />
          </g>
        </svg>
      </Link>
    </>
  );
};

BtnArrow.a = (props) => {
  return (
    <>
      <a
        href={props.link}
        className={`an-button--arrow ${props.extraClasses} 
        ${props.islarge ? "an-button--large" : ""} 
        ${props.islight ? "an-button--light" : ""}`}
        target={props.target}
      >
        <span>{props.name}</span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 24">
          <g id="arrow" transform="translate(-292)">
            <rect
              id="Rectangle_848"
              data-name="Rectangle 848"
              width="28"
              height="24"
              transform="translate(292)"
              fill="currentColor"
              opacity="0"
            />
            <path
              id="bx-up-arrow-alt"
              d="M5.707,3.828V23.414h2V3.828L12,8.121l1.414-1.414L6.707,0,0,6.707,1.414,8.121Z"
              transform="translate(315.414 6) rotate(90)"
              fill="currentColor"
            />
          </g>
        </svg>
      </a>
    </>
  );
};

BtnArrow.propTypes = {
  name: PropTypes.string,
  extraClasses: PropTypes.string,
  link: PropTypes.string,
  islarge: PropTypes.bool,
  islight: PropTypes.bool,
};

export default BtnArrow;
