export const containerVariants = {
  visible: { transition: { staggerChildren: 0.125 } },
};

export const initialContainerVariants = {
  visible: { transition: { delayChildren: 0.3, staggerChildren: 0.1 } },
};

export const textContainerVariants = {
  visible: { transition: { staggerChildren: 0.015 } },
};

export const contentVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { ease: "easeOut", duration: 0.5 },
  },
};

export const imageDelayVariants = {
  ...contentVariants,
  visible: {
    ...contentVariants.visible,
    transition: {
      ...contentVariants.visible.transition,
      delay: 0.5,
    },
  },
}
