import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { AnimateWhenVisible } from "./animateWhenVisible";
import SiteCredit from "./siteCredit";
import { containerVariants, contentVariants } from "../animationVariants";
import ANLogo from "../assets/logo.svg";
import { ReactComponent as URArrow } from "../assets/icons/arrow-up-right.svg";

const navItems = [
  { name: "Home", href: "/" },
  { name: "About", href: "/about" },
  { name: "Business", href: "/our-business" },
  { name: "Contact", href: "/contact-us" },
];

const social = [
  { name: "Facebook", href: "/", extraClass: "facebook" },
  { name: "Linkedin", href: "/", extraClass: "linkedin" },
];

const Footer = () => {
  const history = useHistory();
  const location = useLocation();

  const [hack, setHack] = useState(1);

  useEffect(() => {
    if (hack % 2 === 0) setHack(1);
  }, [hack]);

  const resetFooter = () => setHack(hack + 1);

console.log(location.pathname)

  return hack % 2 === 0 ? null : (
    <motion.footer
      initial="hidden"
      animate="visible"
      whileHover="hover"
      exit="exit"
      variants={containerVariants}
      className={`an-footer ${location.pathname === "/our-business" ? "dark" : ""}`}
    >
      <AnimateWhenVisible rootMargin="-300px">
        <div className="container">
          <div className="an-footer__top">
            <motion.div
              variants={contentVariants}
              className="md:col-span-2 lg:col-span-3 mb-14"
            >
              <Link to="/">
                <span className="sr-only">A and N Ventures Logo</span>
                <img src={ANLogo} className="an-footer__logo" alt="A & N Ventures Logo"/>
              </Link>
            </motion.div>

            <motion.ul variants={containerVariants} className="mb-8">
              {navItems.map((item) => (
                <motion.li variants={contentVariants} key={item.name}>
                  <a
                    href="#"
                    onClick={() => {
                      if (location.pathname !== item.href) {
                        history.push(item.href);
                        resetFooter();
                      }
                    }}
                  >
                    {item.name}
                  </a>
                </motion.li>
              ))}
            </motion.ul>

            <motion.ul variants={containerVariants} className="mb-8">
              {social.map((item) => (
                <motion.li variants={contentVariants} key={item.name}>
                  <a href={item.href} className={item.extraClass}>
                    <span>{item.name}</span>
                    <URArrow className="an-footer__link__arrow" />
                  </a>
                </motion.li>
              ))}
            </motion.ul>
          </div>

          <motion.div
            variants={{
              ...contentVariants,
              visible: {
                ...contentVariants.visible,
                transition: {
                  ...contentVariants.visible.transition,
                  duration: 0.3,
                },
              },
            }}
            className="an-footer__bot"
          >
            <motion.div
              variants={{
                ...contentVariants,
                visible: {
                  ...contentVariants.visible,
                  transition: {
                    ...contentVariants.visible.transition,
                    delay: 0.5,
                  },
                },
              }}
              className="flex items-center mb-4 lg:mb-0"
            >
              <p className="text-dblue">© 2021 — A&amp;N Venture</p>
            </motion.div>
            <motion.div
              variants={{
                ...contentVariants,
                visible: {
                  ...contentVariants.visible,
                  transition: {
                    ...contentVariants.visible.transition,
                    delay: 0.5,
                  },
                },
              }}
              className="lg:col-start-5 flex lg:justify-end"
            >
              <SiteCredit />
            </motion.div>
          </motion.div>
        </div>
      </AnimateWhenVisible>
    </motion.footer>
  );
};

export default Footer;
