import React from "react";
import { motion } from "framer-motion";
import {
  BtnArrow,
  AnimateWhenVisible,
  AnimatedH1,
  AnimatedH2,
} from "../components";
import {
  initialContainerVariants,
  containerVariants,
  contentVariants,
} from "../animationVariants";
import { ReactComponent as ThreeCircle } from "../assets/images/threeCircle.svg";
import LandingImage from "../assets/images/home-page.jpg";
import EnablingAidImage from "../assets/images/enabling-aid.png";

/* const ctaVariants = {
  hidden: { opacity: 0, x: -100 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { ease: "easeOut", duration: 0.75 },
  },
}; */

const threeCirclesVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { ease: "easeOut", duration: 0.5 },
  },
};

const values = [
  {
    name: "Right Sustainability Goals",
    description:
      "We leverage our core competencies and maximize our Environmental, Social and Governance responsibilities as well as their financial performance.",
  },
  {
    name: "Technology across the value chain",
    description:
      "We adopt technology to transform our business processes to achieve sustainability.",
  },
  {
    name: "Governance Led by the Board",
    description:
      "Our Board takes charge of the sustainability issues and follow an inclusive approach by integrating sustainability within the value chain.",
  },
];

const portfolios = [
  {
    name: "Enabling Aid",
    description: `Enabling Aid is a social initiative undertaken by
     A&N Ventures with the aim to be Nepal’s leading provider for daily living aids involving disability, mobility, accessibility, adaptive and rehabilitative equipment. We strive to play a significant role in improving the lives of people with disabilities that impair both structure and functions, restrict activity and participation.`,
    image: EnablingAidImage,
    link: "https://enablingaid.org",
  },
  // {
  //   name: "Est vitae ",
  //   description:
  //     "Vestibulum commodo sapien non elit porttitor, vitae volutpat nibh mollis. Nulla porta risus id neque tempor, in efficitur justo imperdiet. Etiam a ex at ante tincidunt imperdiet. Nunc congue ex vel nisl viverra, sit amet aliquet lectus ullamcorper. ",
  //   image:
  //     "https://images.pexels.com/photos/3194524/pexels-photo-3194524.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  //   link: "/",
  // },
];

const Home = () => {
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      whileHover="hover"
      exit="exit"
    >
      {/*
       * Landing
       */}
      <motion.section
        variants={initialContainerVariants}
        className="an-section--full containerToBeScaled"
      >
        <div className="container grid md:grid-cols-12">
          <div className="mb-16 md:mb-0 md:col-span-8 lg:col-span-6 flex flex-col justify-center">
            <AnimatedH1
              className="an-landing__title an-heading-one text-dblue mb-8 md:mb-14 lg:mb-16"
              title="Fulfilling the daily needs of People and Communities for a Better tomorrow"
            />

            <motion.div variants={contentVariants}>
              <BtnArrow name="Get In Touch" islarge link="/contact-us" />
            </motion.div>
          </div>

          <motion.div
            variants={contentVariants}
            className="md:col-span-4  lg:col-span-5 lg:col-start-8"
          >
            <motion.figure className="an-figure--4-5">
              <img src={LandingImage} alt="" />
            </motion.figure>
          </motion.div>
        </div>
      </motion.section>

      {/*
       * Belief
       */}
      <section className="an-section--container no-space bg-dblue">
        <AnimateWhenVisible>
          <motion.div
            variants={contentVariants}
            className="container grid md:grid-cols-8 lg:grid-cols-12 lg:gap-x-8"
          >
            <div className="md:col-span-7 lg:col-span-9">
              <motion.h4
                variants={contentVariants}
                className="an-heading-four text-white mb-6 lg:mb-8"
              >
                Our Belief
              </motion.h4>

              <AnimatedH2
                className="an-home__content an-heading-one text-white mb-16 md:mb-20 lg:mb-32"
                title="We are primarily concerned with how we impact the world around us in a sustainable manner."
              />
            </div>

            <motion.div
              variants={threeCirclesVariants}
              className="md:col-span-7 lg:col-span-6"
            >
              <div className="sticky top-20">
                <figure className="an-values__circles">
                  <ThreeCircle className="an-values__circles__img" />
                </figure>
              </div>
            </motion.div>

            <div className="md:col-span-7 lg:col-span-4 lg:col-start-8">
              <div className="flex flex-col pt-20 md:pt-28 lg:pt-20">
                {values.map((item, index) => (
                  <AnimateWhenVisible key={index}>
                    <motion.div
                      className={`${
                        index !== values.length - 1 ? "mb-16 md:mb-20" : ""
                      } lg:mb-28`}
                      variants={containerVariants}
                    >
                      <motion.h3
                        variants={contentVariants}
                        className="an-heading-three text-white mb-6"
                      >
                        {item.name}
                      </motion.h3>
                      <motion.p variants={contentVariants} className="light">
                        {item.description}
                      </motion.p>
                    </motion.div>
                  </AnimateWhenVisible>
                ))}
              </div>
            </div>
          </motion.div>
        </AnimateWhenVisible>
      </section>
      {/*
       * Portfolio
       */}
      <motion.section className="an-section--container no-space">
        <div className="container grid gap-y-24 lg:gap-y-44">
          {portfolios.map((item, index) => (
            <AnimateWhenVisible key={index}>
              <motion.div
                variants={containerVariants}
                className="grid md:grid-cols-2 md:gap-x-14 lg:gap-x-24 "
              >
                <motion.div
                  variants={contentVariants}
                  className="mb-10 md:mb-0"
                >
                  <figure className="an-figure--1-1">
                    <img src={item.image} alt={item.name} />
                  </figure>
                </motion.div>

                <div className="max-w-md grid place-content-center">
                  <motion.h2
                    variants={contentVariants}
                    className="an-heading-two mb-4 lg:mb-6"
                  >
                    {item.name}
                  </motion.h2>

                  <motion.p
                    variants={contentVariants}
                    className="mb-10 lg:mb-14"
                  >
                    {item.description}
                  </motion.p>

                  <motion.div variants={contentVariants}>
                    <BtnArrow.a
                      name="Check Website"
                      link={item.link}
                      target="_blank"
                    />
                  </motion.div>
                </div>
              </motion.div>
            </AnimateWhenVisible>
          ))}
        </div>
      </motion.section>

      {/*
       * About
       */}
      <motion.section className="an-section--container no-space bg-ghostWhite">
        <AnimateWhenVisible>
          <div className="container grid md:grid-cols-8 lg:grid-cols-12">
            <div className="md:col-span-7 lg:col-span-11 xl:col-span-9">
              <motion.h4
                variants={contentVariants}
                className="an-heading-four text-dblue mb-6 lg:mb-8"
              >
                About Us
              </motion.h4>

              <AnimatedH2
                className="an-home__content an-heading-one text-dblue mb-12 md:mb-16"
                title="We are engaged in investments, imports, exports and agency representation activities across multiple industries and businesses."
              />

              <motion.div variants={contentVariants}>
                <BtnArrow name="Learn More" islarge link="/about" />
              </motion.div>
            </div>
          </div>
        </AnimateWhenVisible>
      </motion.section>
    </motion.div>
  );
};

export default Home;
