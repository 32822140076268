import React from "react";
import { motion } from "framer-motion";
import { AnimateWhenVisible, AnimatedH1, AnimatedH2 } from "../components";
import {
  containerVariants,
  contentVariants,
  initialContainerVariants,
  imageDelayVariants,
} from "../animationVariants";
import BusinessImage from "../assets/images/business.png";

const businesses = [
  {
    number: "01",
    name: "Investments",
    description:
      "We invest in clean energy ventures and shares, securities, deposit certificate and debentures of investment products. Additionally, we establish industries in joint investment with both national and international companies. We are also engaged in purchase, development, and sale of real estate properties.",
  },
  {
    number: "02",
    name: "Imports & Marketing",
    description:
      "We import various commodities from consumer goods to machineries and many more from foreign entities and engage in its marketing, sales and distribution. We even purchase goods from domestic and foreign countries and act as agents for its marketing and distribution. ",
  },
  {
    number: "03",
    name: "Exports & Promotion",
    description:
      "We export handicrafts, carpets, jewelry, artifacts, agriculture produces, herbs, tea, coffee and commodities produced in Nepal, engage in its purchase from domestic companies and participate in exhibition and trade promotion events",
  },
  {
    number: "04",
    name: "Agency Representation",
    description:
      "We represent national and international entities in capacities of agency, distributorship, commission agent. Alongside, we participate in auctions, tenders and transactions called for by private parties, public enterprises and government entities and we engage in money transfer agent of approved entities.",
  },
];

const Business = () => {
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      whileHover="hover"
      exit="exit"
      variants={containerVariants}
      className="an-header-padding"
    >
      {/*
       * Summary
       */}
      <motion.section
        variants={initialContainerVariants}
        className="an-section--container--small no-space bg-white"
      >
        <div className="container grid md:grid-cols-8 lg:grid-cols-12">
          <div className="md:col-span-7 lg:col-span-9">
            <AnimatedH1
              className="an-business__content an-title-large text-dblue mb-10"
              title="We are involved in different business sectors."
            />
          </div>
        </div>
      </motion.section>

      {/*
       * Image
       */}
      <section className="an-section no-space">
        <AnimateWhenVisible>
          <motion.figure
            variants={imageDelayVariants}
            className="an-figure--16-9"
          >
            <img src={BusinessImage} alt="Doing business" />
          </motion.figure>
        </AnimateWhenVisible>
      </section>

      {/*
       * Business Details
       */}
      <section className="an-section--container no-space">
        <AnimateWhenVisible>
          <div className="container grid md:grid-cols-8 lg:grid-cols-12">
            <div className="md:col-span-7 lg:col-span-9">
              <motion.h4
                variants={contentVariants}
                className="an-heading-four text-dblue mb-6 lg:mb-8"
              >
                Our Business
              </motion.h4>

              <AnimatedH2
                className="an-business__content an-heading-one text-dblue mb-16 md:mb-20"
                title="We invest in clean energy ventures, are involved in imports and exports and many more."
              />
            </div>

            <div className="md:col-span-8 lg:col-span-9">
              <div className="grid md:grid-cols-2 gap-16 md:gap-20">
                {businesses.map((item, index) => (
                  <AnimateWhenVisible key={index}>
                    <motion.div variants={containerVariants}>
                      <motion.h3
                        variants={contentVariants}
                        className="an-heading-one text-dblue mb-4"
                      >
                        {item.number}
                      </motion.h3>
                      <motion.h2
                        variants={contentVariants}
                        className="an-heading-three font-medium text-dblue mb-8"
                      >
                        {item.name}
                      </motion.h2>
                      <motion.p variants={contentVariants} className="max-w-sm">
                        {item.description}
                      </motion.p>
                    </motion.div>
                  </AnimateWhenVisible>
                ))}
              </div>
            </div>
          </div>
        </AnimateWhenVisible>
      </section>
    </motion.div>
  );
};

export default Business;
