import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import ANLogo from "../assets/logo.svg";

const navItems = [
  { name: "About", href: "/about" },
  { name: "Business", href: "/our-business" },
  { name: "Contact", href: "/contact-us" },
];

const navMenuVariant = {
  hidden: {
    y: "-100%",
    transition: {
      type: "tween",
      duration: 0.4,
      ease: "easeIn",
      when: "afterChildren",
    },
  },
  visible: {
    y: 0,
    transition: {
      type: "tween",
      duration: 0.4,
      ease: "easeOut",
    },
  },
};

const navCloseVariant = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { delay: 0.4 },
  },
};

const navMenuContentVariant = {
  visible: { transition: { staggerChildren: 0.5, delayChildren: 0.2 } },
  hidden: { transition: { staggerChildren: 0.05, staggerDirection: -1 } },
};

const navListVariant = {
  visible: { transition: { staggerChildren: 0.07, delayChildren: 0.2 } },
  hidden: { transition: { staggerChildren: 0.05, staggerDirection: -1 } },
};

const navItemsVariant = {
  visible: {
    x: 0,
    opacity: 1,
    transition: { type: "tween", ease: "easeOut" },
  },
  hidden: {
    x: 50,
    opacity: 0,
    transition: { type: "tween", ease: "easeOut" },
  },
};

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ');
// }

const Header = () => {
  let [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <Dialog
            static
            as={motion.div}
            className="fixed inset-0 flex z-40 lg:hidden"
            open={isOpen}
            onClose={setIsOpen}
            variants={navMenuVariant}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <div className="relative  w-full bg-ghostWhite flex flex-col overflow-y-auto ml-auto">
              <div className="fixed w-full top-0 px-5 md:px-8 h-22 md:h-35 items-center flex justify-end">
                <motion.button
                  type="button"
                  className="p-2 rounded-md inline-flex items-center justify-center text-ebony"
                  onClick={() => setIsOpen(false)}
                  variants={navCloseVariant}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </motion.button>
              </div>

              <motion.div
                className="flex flex-1 justify-center flex-col items-center"
                variants={navMenuContentVariant}
              >
                <motion.ul
                  className="grid gap-12 md:gap-20 justify-center relative"
                  variants={navListVariant}
                >
                  <motion.li
                    className="whitespace-nowrap text-center"
                    variants={navItemsVariant}
                  >
                    <NavLink
                      className="text-3xl text-center text-dblue inline-block relative"
                      to="/"
                      onClick={() => setIsOpen(false)}
                    >
                      Home
                    </NavLink>
                  </motion.li>
                  {navItems.map((item) => (
                    <motion.li
                      key={item.name}
                      className="whitespace-nowrap text-center"
                      variants={navItemsVariant}
                    >
                      <NavLink
                        className="text-3xl text-center text-dblue inline-block relative"
                        to={item.href}
                        onClick={() => setIsOpen(false)}
                      >
                        {item.name}
                      </NavLink>
                    </motion.li>
                  ))}
                </motion.ul>
              </motion.div>
            </div>
          </Dialog>
        )}
      </AnimatePresence>

      <header className="an-header">
        <div className="container flex items-center justify-between ">
          {/* logo */}
          <div className="flex">
            <a href="/">
              <span className="sr-only">A and N Ventures Logo</span>
              <img src={ANLogo} className="an-header__logo__icon" alt="A&N Ventures logo"/>
            </a>
          </div>

          {/* Navigation */}
          <nav className="flex-1 hidden lg:block">
            <ul className="an-header__nav-list">
              {navItems.map((item) => (
                <li className="an-header__nav-item" key={item.name}>
                  <NavLink to={item.href} className="an-header__nav-link">
                    {item.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>

          {/* Mobile menu button */}
          <button
            type="button"
            className="bg-white p-2 rounded-md text-ebony lg:hidden"
            onClick={() => setIsOpen(true)}
          >
            <span className="sr-only">Open menu</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>

          {/* <div className="flex gap-x-5 items-center justify-end">
              <a href="#" className="btn-primary--small hidden lg:flex">
                Sign up
              </a>
            </div> */}
        </div>
      </header>
    </>
  );
};
export default Header;
