import React from "react";
import { motion } from "framer-motion";
import {
  AnimateWhenVisible,
  BtnArrow,
  AnimatedH1,
  AnimatedH2,
} from "../components";
import {
  initialContainerVariants,
  containerVariants,
  contentVariants,
  imageDelayVariants,
} from "../animationVariants";
import Timeline from "../components/timeline";
import ArvindImage from "../assets/images/arvind.jpg";
import AboutPageImage from "../assets/images/about-page.jpg";

const businesses = [
  {
    number: "01",
    name: "Investments",
    description:
      "In shares, securities, debentures, clean energy, real estate, properties and many more.",
  },
  {
    number: "02",
    name: "Imports & Marketing",
    description:
      "Of food & beverages, beauty products & cosmetics, fertilizers & seeds, building materials, furniture, garments & more",
  },
  {
    number: "03",
    name: "Exports & Promotion",
    description:
      "Of handicrafts, carpets, jewelry, artefacts, agriculture produces, herbs, tea, coffee and other commodities produced in Nepal.",
  },
  {
    number: "04",
    name: "Agency Representation",
    description:
      "Representing national and international entities in capacities of agency, distributorship, commission agent and more",
  },
];

const founders = [
  {
    name: "Arvind Das Joshi",
    title: "Founder",
    description:
      "Arvind is a fellow chartered accountant with more than 30 years professional experience. He has provided services to a wide range of clientele involved in various business sectors and industries. He has developed networks with professionals and firms, locally and globally. He forayed into business with the establishment of A&N Ventures for the acquisition, restructuring and management of two sick beverage units in 2010. He sets a vision to expand his business in a sustainable manner that meets present needs of people and communities, without compromising the ability of future generations to meet their own needs.",
    image: ArvindImage,
  },
  /* {
    name: "Neeta Pradhan (Joshi)",
    title: "Founder",
    description:
      "Neeta is a certified public accountant and a graduate in accounting with inter-disciplinary and multi-sectorial experiences working as a financial advisor and a senior banker with 4 ‘A’ Class commercial banks in over 17 years. She is also a promoter of a women led micro finance institution and social agro-business and has an in-depth understanding of large scale industrial undertakings.",
    image:
      "https://images.pexels.com/photos/4342352/pexels-photo-4342352.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  }, */
];

const About = () => {
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      whileHover="hover"
      exit="exit"
      className="an-header-padding"
    >
      {/*
       * Summary
       */}
      <motion.section
        variants={initialContainerVariants}
        className="an-section--container--small no-space bg-white"
      >
        <div className="container grid md:grid-cols-8 lg:grid-cols-12">
          <div className="md:col-span-7 lg:col-span-9">
            <AnimatedH1
              className="an-about__content an-title-large text-dblue mb-10"
              title="We are here to uphold businesses that meets the present needs without compromising the future."
            />
          </div>
        </div>
      </motion.section>

      {/*
       * Team image
       */}
      <motion.section
        variants={imageDelayVariants}
        className="an-section no-space"
      >
        <figure className="an-figure--16-9">
          <img src={AboutPageImage} alt="Doing business" />
        </figure>
      </motion.section>

      {/*
       * Our Vision
       */}
      <section className="an-section--container no-space">
        <AnimateWhenVisible>
          <motion.div
            variants={containerVariants}
            className="container grid md:grid-cols-8 lg:grid-cols-12"
          >
            <div className="md:col-span-7 lg:col-span-9">
              <motion.h4
                variants={contentVariants}
                className="an-heading-four text-dblue mb-6 lg:mb-8"
              >
                Our Vision
              </motion.h4>

              <AnimatedH2
                className="an-about__content an-heading-one text-dblue mb-6 md:mb-8"
                title="We are committed to improving the quality of life."
              />

              <motion.p variants={contentVariants} className="max-w-xl">
                Our vision to the serve the essential needs of people, to
                develop and create a better society and to nurture our Planet
                Earth to enable future generations to live in a sustainable
                manner.
              </motion.p>
            </div>
          </motion.div>
        </AnimateWhenVisible>
      </section>

      {/*
       * Business
       */}
      <section className="an-section--container no-space bg-dblue">
        <AnimateWhenVisible>
          <motion.div
            variants={containerVariants}
            className="container grid md:grid-cols-8 lg:grid-cols-12"
          >
            <div className="md:col-span-7 lg:col-span-9">
              <motion.h4
                variants={contentVariants}
                className="an-heading-four text-white mb-6 lg:mb-8"
              >
                Our Business
              </motion.h4>

              <AnimatedH2
                className="an-about__content an-heading-one text-white mb-16 md:mb-20"
                title="We are involved in different business sectors."
              />
            </div>

            <div className="md:col-span-12 mb-16 md:mb-20">
              <div className="grid gap-y-14 md:grid-cols-2 lg:grid-cols-4 md:gap-y-16 gap-x-10">
                {businesses.map((item) => (
                  <motion.div key={item.number} variants={containerVariants}>
                    <motion.h3
                      variants={contentVariants}
                      className="an-heading-one text-white mb-4"
                    >
                      {item.number}
                    </motion.h3>
                    <motion.h2
                      variants={contentVariants}
                      className="an-heading-three font-medium text-white mb-8"
                    >
                      {item.name}
                    </motion.h2>
                    <motion.p
                      variants={contentVariants}
                      className="light max-w-sm"
                    >
                      {item.description}
                    </motion.p>
                  </motion.div>
                ))}
              </div>
            </div>

            <motion.div
              variants={contentVariants}
              className="md:col-span-7 lg:col-span-9"
            >
              <BtnArrow name="Learn More" islight link="/our-business" />
            </motion.div>
          </motion.div>
        </AnimateWhenVisible>
      </section>
      {/*
       * Mission
       */}
      <section className="an-section--container no-space bg-ghostWhite">
        <AnimateWhenVisible>
          <motion.div
            variants={containerVariants}
            className="container grid md:grid-cols-8 lg:grid-cols-12"
          >
            <div className="md:col-span-7 lg:col-span-9">
              <motion.h4
                variants={contentVariants}
                className="an-heading-four text-dblue mb-6 lg:mb-8"
              >
                Our Mission
              </motion.h4>
              <AnimatedH2
                className="an-about__content  an-heading-one text-dblue"
                title="We intend to drive sustainable initiatives throughout our business lines by engaging with our stakeholders on environmental, socially responsible activities and good governance."
              />
            </div>
          </motion.div>
        </AnimateWhenVisible>
      </section>
      {/*
       * History
       */}
      <section className="an-section--container no-space">
        <AnimateWhenVisible>
          <motion.div
            variants={containerVariants}
            className="container grid md:grid-cols-8 lg:grid-cols-12"
          >
            <div className="md:col-span-7 lg:col-span-9">
              <motion.h4
                variants={contentVariants}
                className="an-heading-four text-dblue mb-6 lg:mb-8"
              >
                Our History
              </motion.h4>

              <AnimatedH2
                className="an-about__content an-heading-one text-dblue mb-16 md:mb-20 lg:mb-28"
                title="With over 10 years of history, we are making our way to a better future."
              />
            </div>
          </motion.div>

          <motion.div
            variants={{
              ...contentVariants,
              visible: {
                ...contentVariants.visible,
                transition: {
                  ...contentVariants.visible.transition,
                  delay: 0.5,
                },
              },
            }}
          >
            <Timeline />
          </motion.div>
        </AnimateWhenVisible>
      </section>
      {/*
       * Founders
       */}
      <section className="an-section--container no-space bg-dblue">
        <AnimateWhenVisible>
          <motion.div
            variants={contentVariants}
            className="container grid md:grid-cols-8 lg:grid-cols-12"
          >
            <div className="md:col-span-7 lg:col-span-9">
              <motion.h4
                variants={contentVariants}
                className="an-heading-four text-white mb-6 lg:mb-8"
              >
                Our Founders
              </motion.h4>

              <AnimatedH2
                className="an-about__content an-heading-one text-white mb-16 md:mb-20"
                title="Our founders are seasoned and have years of experience."
              />
            </div>

            <div className="grid gap-y-20 md:col-span-12 lg:col-span-9">
              {founders.map((item) => (
                <AnimateWhenVisible key={item.name}>
                  <motion.div
                    variants={containerVariants}
                    className="grid md:grid-cols-2 items-center gap-x-16"
                  >
                    <motion.figure
                      variants={contentVariants}
                      className="an-figure--4-5 mb-8 md:mb-0"
                    >
                      <img src={item.image} alt={item.name} />
                    </motion.figure>
                    <div>
                      <motion.h2
                        variants={contentVariants}
                        className="an-heading-two text-white mb-3 md:mb-4"
                      >
                        {item.name}
                      </motion.h2>
                      <motion.h3
                        variants={contentVariants}
                        className="an-heading-three text-white mb-6 md:mb-8"
                      >
                        {item.title}
                      </motion.h3>
                      <motion.p variants={contentVariants} className="light">
                        {item.description}
                      </motion.p>
                    </div>
                  </motion.div>
                </AnimateWhenVisible>
              ))}
            </div>
          </motion.div>
        </AnimateWhenVisible>
      </section>
    </motion.div>
  );
};

export default About;
