import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { sendForm } from "emailjs-com";
import {
  BtnArrow,
  AnimateWhenVisible,
  AnimatedH1,
  AnimatedH2,
} from "../components";
import { containerVariants, contentVariants } from "../animationVariants";
import { ReactComponent as URArrow } from "../assets/icons/arrow-up-right.svg";

const social = [
  { name: "Facebook", href: "/", extraClass: "facebook" },
  { name: "Linkedin", href: "/", extraClass: "linkedin" },
];

const Contact = () => {
  const formRef = useRef();

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [message, setMessage] = useState("");
  const [isSending, setSending] = useState(false);

  useEffect(() => setFirstNameError(""), [firstName]);
  useEffect(() => setLastNameError(""), [lastName]);
  useEffect(() => setEmailError(""), [email]);
  useEffect(() => setPhoneError(""), [phone]);

  const changeFirstName = (event) => setFirstName(event.target.value);
  const changeLastName = (event) => setLastName(event.target.value);
  const changeEmail = (event) => setEmail(event.target.value);
  const changePhone = (event) => setPhone(event.target.value);
  const changeMessage = (event) => setMessage(event.target.value);

  const validateFirstName = () => {
    if (
      firstName !== "" &&
      !/^[a-zA-Zàáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż]+$/.test(
        firstName.toLowerCase()
      )
    ) {
      setFirstNameError("First Name should only contain alphabets");
    }
  };

  const validateLastName = () => {
    if (
      lastName !== "" &&
      !/^[a-zA-Zàáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż]+$/.test(
        lastName.toLowerCase()
      )
    ) {
      setLastNameError("Last Name should only contain alphabets");
    }
  };

  const validateEmail = () => {
    if (
      email !== "" &&
      !/^"?[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*"?@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[A-Z]{2}|com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum|co)(.[A-Za-z]{2})?\b$/.test(
        email
      )
    )
      setEmailError("Unrecognized Email Format");
  };

  const validatePhone = () => {
    if (
      phone !== "" &&
      !/^\b((98(0|1|2)[0-9]{7})|(98(4|6)[0-9]{7})|(985[0-9]{7})|(97(4|5)[0-9]{7})|(972[0-9]{7})|(987[0-9]{7})|(9(88|(6(1|2)))[0-9]{7}))\b$/.test(
        phone
      )
    ) {
      setPhoneError("Unrecognized Phone Format");
    }
  };

  const validateForm = () => {
    let isValid = false;
    validateEmail();
    validatePhone();

    if (!firstNameError || !lastNameError || !emailError || !phoneError)
      isValid = true;

    return isValid;
  };

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      try {
        setSending(true);

        // await new Promise((resolve) => setTimeout(resolve, 1000));

        const response = await sendForm(
          "service_loxvfwg",
          "template_5av9j3f",
          formRef.current,
          "user_7FivWY7zx1Jk1EUC9o9la"
        );

        console.log(response);

        clearForm();
      } catch (error) {
        console.error(error);
      } finally {
        setSending(false);
      }
    }
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      whileHover="hover"
      exit="exit"
      className="an-header-padding"
    >
      {/*
       * Summary
       */}
      <motion.section className="an-section--container--small no-space bg-white">
        <motion.div
          variants={{ visible: { transition: { delayChildren: 0.2 } } }}
          className="container"
        >
          <AnimatedH1
            className="an-title-large text-dblue mb-16 md:mb-20 lg:mb-24"
            title="Get In Touch"
          />

          <motion.div
            variants={{
              visible: {
                transition: { delayChildren: 0.2, staggerChildren: 0.05 },
              },
            }}
            className="grid gap-y-16 md:grid-cols-2 lg:grid-cols-4 mb-16"
          >
            <div>
              <motion.h3
                variants={contentVariants}
                className="an-heading-three mb-5"
              >
                Address
              </motion.h3>
              <motion.p variants={contentVariants} className="mb-6">
                04, Shantipriya Marg, <br />
                Maharajgunj - 03, <br />
                Kathmandu, Nepal
              </motion.p>

              <motion.div variants={contentVariants}>
                <BtnArrow.a
                  name="View on Map"
                  link="https://goo.gl/maps/GiMQHyLMHfErCsqV6"
                  target="_blank"
                />
              </motion.div>
            </div>

            <div>
              <motion.h3
                variants={contentVariants}
                className="an-heading-three mb-5"
              >
                Phone
              </motion.h3>
              <motion.p variants={contentVariants}>
                +977-9801030786 <br />
                +977-9851030766
              </motion.p>
            </div>

            <div>
              <motion.h3
                variants={contentVariants}
                className="an-heading-three mb-5"
              >
                Mail Us
              </motion.h3>
              <motion.p variants={contentVariants}>
                <a href="mailto:info@anventures.org?subject=Your%20Title&body=Your%20Message">
                  info@anventures.org
                </a>
              </motion.p>
            </div>

            <div>
              <motion.h3
                variants={contentVariants}
                className="an-heading-three mb-5"
              >
                Follow us
              </motion.h3>
              <motion.ul
                variants={contentVariants}
                className="an-contact__social"
              >
                {social.map((item) => (
                  <li key={item.name}>
                    <a href={item.href} className={item.extraClass}>
                      <span>{item.name}</span>
                      <URArrow className="an-contact__social__icon" />
                    </a>
                  </li>
                ))}
              </motion.ul>
            </div>
          </motion.div>
        </motion.div>
      </motion.section>

      {/*
       * Image
       */}
      <section className="an-section--container no-space bg-ghostWhite">
        <AnimateWhenVisible>
          <div className="container">
            <AnimatedH2
              className="an-heading-two mb-16 md:mb-20"
              title="Send us a message"
            />

            <motion.form
              ref={formRef}
              variants={containerVariants}
              className="lg:w-3/4 flex flex-wrap justify-between"
              onSubmit={handleSubmit}
            >
              <motion.div variants={contentVariants} className="an-form__half">
                <span className="an-form__error">{firstNameError}</span>
                <input
                  name="first_name"
                  type="text"
                  id="fname"
                  className="an-form__input"
                  placeholder="First Name"
                  value={firstName}
                  onChange={changeFirstName}
                  onBlur={validateFirstName}
                  required
                />
                <label className="an-form__label" htmlFor="fname">
                  First Name
                </label>
              </motion.div>

              <motion.div variants={contentVariants} className="an-form__half">
                <span className="an-form__error">{lastNameError}</span>
                <input
                  name="last_name"
                  type="text"
                  id="lname"
                  className="an-form__input"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={changeLastName}
                  onBlur={validateLastName}
                  required
                />
                <label className="an-form__label" htmlFor="lname">
                  Last Name
                </label>
              </motion.div>

              <motion.div variants={contentVariants} className="an-form__half">
                <span className="an-form__error">{emailError}</span>
                <input
                  name="email"
                  type="email"
                  id="email"
                  className="an-form__input"
                  placeholder="email@address.com"
                  value={email}
                  onChange={changeEmail}
                  onBlur={validateEmail}
                  // onSubmit={validateEmail}
                  required
                />
                <label className="an-form__label" htmlFor="email">
                  Email Address
                </label>
              </motion.div>

              <motion.div variants={contentVariants} className="an-form__half">
                <span className="an-form__error">{phoneError}</span>
                <input
                  name="phone"
                  type="text"
                  id="phone"
                  className="an-form__input"
                  placeholder="98########"
                  value={phone}
                  onChange={changePhone}
                  onBlur={validatePhone}
                  required
                />
                <label className="an-form__label" htmlFor="phone">
                  Phone
                </label>
              </motion.div>

              <motion.div variants={contentVariants} className="an-form__full">
                <textarea
                  name="message"
                  id="message"
                  rows="5"
                  className="an-form__input"
                  placeholder="Message"
                  value={message}
                  onChange={changeMessage}
                  required
                />
                <label className="an-form__label" htmlFor="message">
                  Message
                </label>
              </motion.div>

              <motion.div
                variants={contentVariants}
                className="w-full flex justify-end"
              >
                <input
                  type="submit"
                  value={`${isSending ? "Sending..." : "Send Message"}`}
                  className="an-button"
                  disabled={isSending}
                />
              </motion.div>
            </motion.form>
          </div>
        </AnimateWhenVisible>
      </section>
    </motion.div>
  );
};

export default Contact;
