import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

// Import Swiper styles
// import 'swiper/css';

const history = [
  {
    date: "2021",
    title: "Growth",
    activities: [
      {
        description:
          "Company objectives expanded to include Import, Export and Representation business activities",
      },
      {
        description:
          "Partnership agreement signed with Phoenix Medical Systems, India for Arise Wheelchair",
      },
    ],
  },
  {
    date: "2019",
    title: "Partial Divestment",
    activities: [
      {
        description:
          "Partial divestment of Himalayan Brewery, Trishakti Distillery and Himalayan Brewery Resort & Residences",
      },
    ],
  },
  {
    date: "2018",
    title: "Kickstarting",
    activities: [
      {
        description:
          "Restructured Assets and established Himalayan Brewery Resorts & Residences",
      },
    ],
  },
  {
    date: "2010",
    title: "Humble Beginnings",
    activities: [
      { description: "Established A&N Ventures Pvt. Ltd." },
      {
        description:
          "Acquisition of industrial units - Himalayan Brewery and Trishakti Distillery",
      },
    ],
  },
];

const Timeline = () => {
  const [containerGap, setContainerGap] = useState();
  const size = useWindowSize();

  const [swiperRef, setSwiperRef] = useState(null);

  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });
      }

      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
  }

  useEffect(() => {
    function calcContainerGap() {
      const containerWidth =
        document.querySelectorAll(".container")[1].offsetWidth;
      let padding = 20;

      if (size.width >= 640) padding = 32;

      const gap = (size.width - containerWidth) / 2 + padding;
      setContainerGap(gap);
    }

    calcContainerGap();
  }, [size]);

  return (
    <div className="w-full">
      <Swiper
        className="an-timeline"
        onSwiper={setSwiperRef}
        spaceBetween={0}
        slidesPerView={1.2}
        updateOnWindowResize
        grabCursor
        resistanceRatio={0.75}
        observer
        slidesOffsetBefore={containerGap}
        slidesOffsetAfter={containerGap}
        breakpoints={{
          768: { slidesPerView: 2.2 },
          1024: { slidesPerView: 3 },
        }}
        // onSlideChange={() => console.log('slide change')}
        // onSlideChange={(swiper) => swiper.update()}
      >
        {history.map((item) => (
          <SwiperSlide className="an-timeline__slide" key={item.date}>
            <h2 className="an-heading-two">{item.date}</h2>
            <div className="an-timeline__line"></div>
            <h3 className="an-heading-four font-medium mb-2">{item.title}</h3>
            <div className="an-timeline__content">
              {item.activities.map((activities, index) => (
                <p key={index}>{activities.description}</p>
              ))}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="container flex justify-end items-center mt-8 md:mt-12 lg:mt-16">
        <button
          className="an-timeline__nav--prev"
          onClick={() => swiperRef.slidePrev()}
        >
          <span className="sr-only">Previous</span>

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 32">
            <g id="arrow-right" transform="translate(-306 -3885)">
              <rect
                id="icon_container"
                data-name="icon container"
                width="56"
                height="32"
                transform="translate(306 3885)"
                opacity="0"
              />
              <g
                id="arrow-right-2"
                data-name="arrow-right"
                transform="translate(336.667 3891.667)"
              >
                <path
                  id="Path_280"
                  data-name="Path 280"
                  d="M-24,0H18.667"
                  transform="translate(0 9.333)"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                />
                <path
                  id="Path_279"
                  data-name="Path 279"
                  d="M12,5l9.333,9.333L12,23.667"
                  transform="translate(-2.667 -5)"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                />
              </g>
            </g>
          </svg>
        </button>
        <button
          className="an-timeline__nav--next"
          onClick={() => swiperRef.slideNext()}
        >
          <span className="sr-only">Next</span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 32">
            <g id="arrow-right" transform="translate(-306 -3885)">
              <rect
                id="icon_container"
                data-name="icon container"
                width="56"
                height="32"
                transform="translate(306 3885)"
                opacity="0"
              />
              <g
                id="arrow-right-2"
                data-name="arrow-right"
                transform="translate(336.667 3891.667)"
              >
                <path
                  id="Path_280"
                  data-name="Path 280"
                  d="M-24,0H18.667"
                  transform="translate(0 9.333)"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                />
                <path
                  id="Path_279"
                  data-name="Path 279"
                  d="M12,5l9.333,9.333L12,23.667"
                  transform="translate(-2.667 -5)"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                />
              </g>
            </g>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Timeline;
